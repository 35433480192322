<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Rb.</th>
          <th>Takmičari</th>
          <th>Tip</th>
          <th v-if="showClubName">{{ $tc('club.club_name') }}</th>
          <th>{{ $tc('settings.discipline') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(registration, index) in data" :key="registration.id">
          <td>{{ index + 1 }}.</td>
          <td>{{ getCompetitorNames(registration) }}</td>
          <td>{{ registration.type | teamType }}</td>
          <td v-if="showClubName">{{ registration.club.shortName }}</td>
          <td>
            <div
              v-for="(item, index) in getDisciplinesDataList(registration.categories)"
              :key="index"
              class="data-list-item">
              <div v-if="item.label" class="label">{{ item.label }}</div>
              <div v-if="item.text" class="text">{{ item.text }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    showClubName: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getCompetitorNames (item) {
      const keys = ['competitor', 'competitorTwo', 'competitorThree', 'competitorFour']

      const competitors = keys.map(key => item[key]).filter(item => !!item).map(competitor => `${competitor.firstName} ${competitor.lastName} (${competitor.isMale ? 'M' : 'Ž'})`)

      return competitors.join(', ')
    },

    getDisciplinesDataList (categories) {
      return categories?.reduce((acc, item) => {
        acc.push({ label: item.category?.name })
        const discplines = item.disciplines?.map(disc => ({ text: disc.discipline.name }))
        acc.push(...discplines)
        return acc
      }, [])
    }
  }
}
</script>
