<template>
  <panel :title="tournament.name">
    <template v-slot:actions>
      <el-dropdown v-if="user.isAdmin" trigger="click" @command="handleCommand">
        <el-button type="primary" icon="el-icon-more" circle />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="edit"><i class="el-icon-edit" />{{ $tc('tournament.edit_tournament') }}</el-dropdown-item>
          <el-dropdown-item command="close"><i class="el-icon-close" />{{ $tc('tournament.close_applications') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>

    <div v-if="tournament.cover" class="cover">
      <img :src="$file('tournaments', tournament.cover, tournament.id)" />
    </div>

    <div v-if="tournament.images.length" class="gallery">
      <div
        v-for="image in tournament.images"
        :key="image.id"
        class="image">
        <img
          :src="$file('images', image.path)"
          @click="openPreviewImage(image.path)" />
        <div v-if="user.isAdmin" class="delete" @click="askDeleteImage(image)">
          <i class="el-icon-delete" />
        </div>
      </div>
    </div>

    <h4>{{ $tc('form.description') }}:</h4>
    <div class="description" v-html="tournament.description" />

    <template v-if="tournament.documents.length">
      <h4>{{ $tc('general.documents') }}:</h4>

      <div class="documents">
        <file
          v-for="document in tournament.documents"
          class="document"
          :key="document.id"
          :file="document"
          :url="$file('documents', document.name)"
          :deletable="user.isAdmin"
          @delete="onDeleteDocument(document)" />
      </div>
    </template>

    <manage-tournament
      v-if="editTournament"
      :tournament="tournament"
      @add="close()"
      @edit="close()"
      @close="close()" />

    <el-dialog
      :visible.sync="galleryVisible"
      width="90%">
      <img style="max-width: 100%" :src="$file('images', previewImage)" />
    </el-dialog>
  </panel>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

import File from '@/components/files/File'
import Panel from '@/components/panel/Panel'
import ManageTournament from './ManageTournament'

export default {
  components: {
    File,
    Panel,
    ManageTournament
  },

  props: {
    tournament: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      galleryVisible: false,
      previewImage: null,
      editTournament: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  watch: {
    galleryVisible (value) {
      if (!value) this.previewImage = null
    }
  },

  methods: {
    async askDeleteImage (image) {
      try {
        await this.$confirm('Da li si siguran da želiš da uklonite ovu sliku?')
        this.onDeleteImage(image)
      } catch {}
    },

    async onDeleteImage (image) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('tournamentImages/delete', image.id)
        this.$store.commit('tournaments/removeImage', {
          tournament: this.tournament,
          id: image.id
        })
        this.$message.success('Uspešno ste uklonili sliku.')
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    },

    async onDeleteDocument (document) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('documents/delete', document.id)
        this.$store.commit('tournaments/removeDocument', {
          tournament: this.tournament,
          id: document.id
        })
        this.$message.success('Uspešno ste uklonili dokument.')
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    },

    openPreviewImage (path) {
      this.previewImage = path
      this.galleryVisible = true
    },

    handleCommand (command) {
      if (command === 'edit') this.editTournament = true
      if (command === 'close') this.close()
    },

    close () {
      this.editTournament = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .cover {
    width: 100%;
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }

  .description {
    margin: 0;
  }

  .gallery {
    display: flex;
    overflow-x: auto;
    .image {
      position: relative;
      border: 4px solid transparent;
      img {
        display: block;
        cursor: pointer;
        height: 100px;
        transition: all ease .2s;
      }
      .delete {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        color: #fff;
        background-color: rgba(0,0,0, .6);
        transition: all ease .2s;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          background-color: rgba(0,0,0, .8);
        }
      }

      &:hover {
        border-color: $primary;
        .delete {
          opacity: 1;
        }
      }
    }

      .image + .image {
        margin-left: 10px;
      }
  }

  .documents {
    display: flex;
    flex-wrap: wrap;
    .document {
      margin-right: 10px;
    }

  }
</style>
