export default {
  general: {
    home: 'Home',
    print: 'Print',
    informations: 'Informations',
    dashboard: 'Dashboard',
    other: 'Other',
    club: 'Federation | Federations',
    registration_fee: 'Registration fee',
    registration_fee_discount: 'Registration fee discount',
    male: 'Male',
    female: 'Female',
    mixed: 'Mixed',
    save: 'Save',
    type: 'Type',
    both: 'Both',
    men: 'Men',
    women: 'Women',
    cancel: 'Cancel',
    edit: 'Edit',
    create: 'Create',
    actions: 'Actions',
    next: 'Next',
    ops: 'Ooops!',
    prev_step: 'Previous step',
    page_doesnt_exists: "The page you are trying to access doesn't exist.",
    back_to_home: 'Back to home',
    function: 'Function',
    documents: 'Documents',
    images: 'Images',
    n0: 'No.',
    cover_image: 'Cover image'
  },

  menu: {
    my_club: 'My Federation',
    open_tournament: 'Open tournament',
    admin_panel: 'Admin Panel',
    users: 'Users'
  },

  auth: {
    login: 'Login',
    log_out: 'Log out',
    register: 'Register',
    no_account_register: "Don't have an account? Register",
    have_account_login: 'Have an account? Log in',
    sign_in: 'Sign in',
    sign_up: 'Sign up',
    profile_information: 'Profile information',
    club_info: 'Federation information',
    forgot_password: 'Forgot password?',
    reset_password: 'Reset password',
    add_coach: 'Add Coach'
  },

  form: {
    name: 'Name',
    first_name: 'First name',
    last_name: 'Last name',
    city: 'City',
    address: 'Address',
    username: 'Username',
    price: 'Price',
    website: 'Website',
    contact_person: 'Contact person',
    discount: 'Discount',
    description: 'Description',
    mobile: 'Phone number',
    email: 'Email',
    enter_email: 'Enter email',
    password: 'Password',
    enter_password: 'Enter password',
    image: 'Image',
    date_of_birth: 'Date of birth',
    gender: 'Gender',
    belt: 'Belt',
    day: 'Day',
    no_certificate_wff: 'No. certificate WFF',
    no_referee_certificate_wff: 'No. referee certificate WFF',
    no_fudokan_passport: 'No. Fudokan Passport'
  },

  tournament: {
    teams: 'Teams',
    competitors: 'Competitors',
    tournament_name: 'Tournament name',
    tournament: 'Tournament | Tournaments',
    create_tournament: 'Create tournament',
    all_registered_competitors: 'All registered competitors',
    all_registered_teams: 'All registered teams',
    my_registered_competitors: 'My registered competitors',
    my_registered_teams: 'My registered teams',
    close_applications: 'Close applications',
    edit_tournament: 'Edit tournament',
    registration_start_date: 'Registration start date',
    registration_end_date: 'Registration end date',
    single_disciplines: 'Single disciplines',
    team_disciplines: 'Team disciplines',
    number_of_registered_competitors: 'Number of registered competitors',
    number_of_registered_disciplines: 'Number of registered disciplines',
    number_of_registered_team_disciplines: 'Number of registered team disciplines',
    number_of_registered_clubs: 'Number of registered Federations',
    ask_delete_tournament: 'Are you sure you want to delete this tournament',
    register_competitor: 'Register competitor',
    register_team: 'Register team'
  },

  club: {
    coach: 'Coach | Coaches',
    official: 'Official | Officials',
    referee: 'Referee | Referees',
    referee_rang: 'Referee rang',
    club_name: 'Federation name',
    function: 'Function',
    add_official: 'Add official',
    short_name: 'Short federation name',
    my_federation_information: 'My federation information',
    ask_delete_club: 'Are you sure you want to delete this federation'
  },

  profile: {
    my_profile: 'My profile',
    profile_settings: 'Profile settings',
    change_password: 'Change password',
    current_password: 'Current password',
    new_password: 'New password'
  },

  settings: {
    settings: 'Settings',
    category: 'Category | Categories',
    discipline: 'Discipline | Disciplines',
    allowed_sexes: 'Allowed sexes',
    general_settings: 'General settings',
    website_name: 'Website name',
    website_description: 'Website description',
    logo: 'Logo',
    create_category: 'Create category',
    create_discipline: 'Create discipline',
    edit_discipline: 'Edit discipline',
    edit_category: 'Edit category',
    one_discipline: 'One discipline',
    two_disciplines: 'Two disciplines',
    three_or_more_disciplines: 'Three or more disciplines',
    team_disciplines: 'Team disciplines',
    discipline_always_chargeable: 'This discipline is always chargeable',
    discipline_not_chargeable: 'This discipline is not chargeable',
    category_not_chargeable: 'This category is not chargeable'
  },

  messages: {
    success_sign_in: 'Signed in successfully.',
    error_email_password_combination: 'Wrong email/password combination.',
    success_register: 'You have successfully registered. You can log in now.',
    settings_successfully_updated: 'Settings successfully updated.',
    success_remove_discipline: 'Discipline successfully removed.',
    success_remove_category: 'Category successfully removed.',
    ask_remove_discipline: 'Are you sure you want to remove this discipline',
    ask_remove_category: 'Are you sure you want to remove this category',
    ask_remove_coach: 'Are you sure you want to remove this coach',
    ask_remove_team: 'Are you sure you want to remove this team',
    ask_remove_referee: 'Are you sure you want to remove this referee',
    ask_delete_official: 'Are you sure you want to delete this official',
    ask_delete_tournament: 'Are you sure you want to delete this tournament?',
    success_updated_referee: 'Successfully updated referee.',
    success_created_referee: 'Successfully created referee.',
    success_updated_discipline: 'Successfully updated discipline.',
    success_created_discipline: 'Successfully created discipline.',
    success_updated_category: 'Successfully updated category.',
    success_created_category: 'Successfully created category.',
    success_updated_profile: 'Successfully updated profile.',
    success_changed_password: 'Password changed successfully.',
    success_deleted_tournament: 'Successfully deleted tournament.',
    success_deleted_team: 'Successfully deleted team.',
    success_deleted_referee: 'Successfully deleted referee.',
    success_deleted_club: 'Successfully deleted federation.',
    success_deleted_official: 'Successfully deleted official.',
    success_deleted_coach: 'Successfully deleted coach.',
    success_updated_coach: 'Successfully updated coach.',
    success_created_coach: 'Successfully created coach.'
  },

  validation: {
    required: 'This field is required.',
    isTruthy: 'This field is required.',
    email: 'Enter a valid email address.',
    integer: 'This field must be a number.',
    url: 'Enter a valid URL.',
    minLength: 'More characters needed.'
  },

  changes: {
    REGISTER_COMPETITOR: 'registered competitor',
    COMPETITOR_UPDATE: 'updated competitor',
    COMPETITOR_DELETE: 'deleted competitor',
    REGISTER_TEAM: 'registered a team',
    TEAM_UPDATE: 'updated team',
    TEAM_DELETE: 'deleted team'
  }
}
