import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import Vuelidate from 'vuelidate'
import VueTeleport from '@desislavsd/vue-teleport'
import i18n from '@/i18n'

import './config'
import './common/filters'
import './common/components'
import '@/assets/scss/app.scss'

Vue.use(VueTeleport)
Vue.use(Vuelidate)
Vue.use(ElementUI, { locale })
Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
