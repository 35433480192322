<template>
  <panel ref="panel" :title="`${$tc('club.referee', 2)} (${referees.length})`" collapsable>
    <template v-slot:actions>
      <el-button
        v-if="canEdit"
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="$refs.panel.expanded = true; openManageReferee = true">
        {{ $tc('auth.add_coach') }}
      </el-button>

      <el-tooltip v-if="referees.length" :content="$tc('general.print')" placement="top">
        <el-button
          icon="el-icon-printer"
          size="medium"
          circle
          @click="openPrint($tc('club.referee', 2), $refs.printTable ? $refs.printTable.$el : null)" />
      </el-tooltip>
    </template>

    <referees-table
      :data="referees"
      :can-edit="canEdit"
      :custom-logic="customLogic"
      :show-club-name="showClubName"
      @edit="openEdit($event)"
      @custom-delete="$emit('custom-delete', $event)"
      @delete="$emit('delete', $event)" />

    <print-referees-table ref="printTable" v-show="false" :data="referees" :show-club-name="showClubName" />

    <manage-referee
      v-if="openManageReferee"
      :club="club"
      :referee="editing"
      :custom-logic="customLogic"
      @edit="onEdit($event)"
      @custom-add="$emit('custom-add', $event); close()"
      @add="$emit('add', $event); close()"
      @close="close()" />
  </panel>
</template>

<script>
import openPrint from '@/common/print'

import RefereesTable from './RefereesTable'
import ManageReferee from './ManageReferee'
import PrintRefereesTable from './PrintRefereesTable'

export default {
  components: {
    RefereesTable,
    ManageReferee,
    PrintRefereesTable
  },

  props: {
    club: {
      type: Object,
      default: null
    },

    showClubName: {
      type: Boolean,
      default: false
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    referees: {
      type: Array,
      default: null
    },

    customLogic: {
      type: Boolean,
      default: null
    }
  },

  data () {
    return {
      openPrint,

      editing: null,
      openManageReferee: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  methods: {
    onEdit (referee) {
      const club = this.$store.getters['clubs/get'](referee.clubId)
      if (club) {
        this.$store.commit('clubs/setSingle', this.getClubUpdateReferee(club, referee))
      }

      if (this.user.club?.id === referee.clubId) {
        this.$store.commit('auth/updateUsersClub', this.getClubUpdateReferee(this.user.club, referee))
      }

      this.close()
    },

    getClubUpdateReferee (club, referee) {
      const idx = club.referees?.findIndex(m => m.id === referee.id)
      const newData = [...club.referees]
      newData[idx] = referee
      club.referees = newData
      return club
    },

    openEdit (referee) {
      this.editing = referee
      this.openManageReferee = true
    },

    close () {
      this.editing = null
      this.openManageReferee = false
    }
  }
}
</script>
