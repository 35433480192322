<template>
  <el-table
    :data="data"
    style="width: 100%">
    <el-table-column
      :label="$tc('form.name')">
      <template slot-scope="scope">
        {{ scope.row.name }}
      </template>
    </el-table-column>

    <el-table-column
      :label="$tc('general.registration_fee')"
      width="200px"
      align="center">
      <template slot-scope="scope">
        <i :class="scope.row.noPay ? 'el-icon-error' : 'el-icon-success'" />
      </template>
    </el-table-column>

    <el-table-column
      v-if="canEdit"
      :label="$tc('general.actions')"
      align="right">
      <template slot-scope="scope">
        <el-button icon="el-icon-edit" circle @click="$emit('edit', scope.row)" />
        <el-button type="danger" icon="el-icon-delete" circle @click="beforeRemove(scope.row)" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    async beforeRemove (category) {
      try {
        await this.$confirm(`${this.$tc('messages.ask_remove_category')}: ${category.name}`)

        this.onDelete(category)
      } catch {}
    },

    async onDelete (category) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('categories/delete', category.id)
        this.$message.success(this.$tc('messages.success_remove_category'))
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-icon-error {
    color: $danger;
  }
  .el-icon-success {
    color: $success;
  }
</style>
