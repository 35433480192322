<template>
  <panel :title="$tc('settings.discipline', 2)">
    <template v-slot:actions>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="openManageDiscipline = true">
        {{ $tc('settings.create_discipline') }}
      </el-button>
    </template>

    <error-box v-if="error" :loading="loading" @reload="load()" />

    <discipline-table
      v-if="!error"
      :data="disciplines"
      @edit="openEdit($event)"
      can-edit
    />

    <discipline-manage
      v-if="openManageDiscipline"
      :discipline="editing"
      @add="close(); load()"
      @edit="close(); load()"
      @close="close()"
    />

  </panel>
</template>

<script>
import DisciplineTable from './DisciplineTable.vue'
import DisciplineManage from './DisciplineManage.vue'

export default {
  components: {
    DisciplineTable,
    DisciplineManage
  },

  data () {
    return {
      editing: null,
      openManageDiscipline: false,

      error: false,
      loading: false
    }
  },

  computed: {
    disciplines () {
      return this.$store.state.disciplines.data
    }
  },

  created () {
    this.$store.commit('app/set', {
      title: this.$tc('settings.discipline', 2),
      breadcrumbs: [
        {
          text: this.$tc('settings.settings'),
          to: { name: 'Settings' }
        },
        {
          text: this.$tc('settings.discipline', 2)
        }
      ]
    })
  },

  methods: {
    openEdit (category) {
      this.editing = category
      this.openManageDiscipline = true
    },

    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('disciplines/load')
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    close () {
      this.editing = null
      this.openManageDiscipline = false
    }
  }
}
</script>
