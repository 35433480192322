<template>
  <panel ref="panel" :title="`${title} (${teams.length})`" collapsable initially-expanded>
    <template v-slot:actions>
      <el-button
        v-if="tournament.isActive && canEdit"
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="$refs.panel.expanded = true; openManage = true">
        {{ $tc('tournament.register_team') }}
      </el-button>

      <template v-if="teams.length">
        <el-tooltip :content="$tc('general.registration_fee')" placement="top">
          <el-button
            icon="el-icon-tickets"
            size="medium"
            circle
            @click="$emit('registration-fee')" />
        </el-tooltip>

        <el-tooltip :content="$tc('general.print')" placement="top">
          <el-button
            icon="el-icon-printer"
            size="medium"
            circle
            @click="openPrint(title, $refs.printTable ? $refs.printTable.$el : null)" />
        </el-tooltip>
      </template>
    </template>

    <teams-table :data="teams" @remove="updateTournament($event)" @edit="openEdit($event)" :can-edit="canEdit" />

    <print-teams-table ref="printTable" v-show="false" :data="teams" />

    <manage-team
      v-if="openManage"
      :club="club"
      :tournament="tournament"
      :team="editing"
      @edit="updateTournament($event)"
      @add="updateTournament($event)"
      @close="close()" />
  </panel>
</template>

<script>
import openPrint from '@/common/print'

import ManageTeam from './ManageTeam'
import TeamsTable from './TeamsTable'
import PrintTeamsTable from './PrintTeamsTable'

export default {
  components: {
    TeamsTable,
    ManageTeam,
    PrintTeamsTable
  },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    club: {
      type: Object,
      default: null
    },

    title: {
      type: String,
      default: 'Prijavljene ekipe'
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    teams: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      editing: null,
      openManage: false,
      openPrint
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  methods: {
    updateTournament (tournament) {
      this.$store.commit('tournaments/setSingle', tournament)
      if (tournament.isActive) {
        this.$store.commit('app/setActiveTournament', tournament)
      }
      this.close()
    },

    openEdit (team) {
      this.editing = team
      this.openManage = true
    },

    close () {
      this.editing = null
      this.openManage = false
    }
  }
}
</script>
