<template>
  <main-drawer :title="`${isEdit ? $tc('settings.edit_discipline') : $tc('settings.create_discipline')}`" @close="$emit('close')">
    <main-input v-model="form.name" :label="$tc('form.name')" :validator="$v.form.name" />
    <main-select v-model="form.categories" :label="$tc('settings.category', 2)" :options="categoryOptions" :validator="$v.form.categories" multiple />

    <main-select v-model="form.forSex" :label="$tc('settings.allowed_sexes')" :options="sexOptions" />

    <main-checkbox v-model="form.alwaysPay" :label="$tc('settings.discipline_always_chargeable')" />
    <main-checkbox v-if="!form.alwaysPay" v-model="form.noPay" :label="$tc('settings.discipline_not_chargeable')" />

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">{{ $tc('general.cancel') }}</el-button>
        <el-button type="primary" @click="save()">{{ isEdit ? $tc('general.edit') : $tc('general.create') }}</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    discipline: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        name: null,
        categories: null,
        forSex: null,
        noPay: false,
        alwaysPay: false
      },

      sexOptions: [
        {
          id: null,
          text: this.$tc('general.both')
        },
        {
          id: 1,
          text: this.$tc('general.men')
        },
        {
          id: 2,
          text: this.$tc('general.women')
        }
      ]
    }
  },

  computed: {
    categoryOptions () {
      return this.categories?.map(d => ({
        id: d.id,
        text: d.name
      })) || []
    },

    categories () {
      return this.$store.state.categories.data
    },

    isEdit () {
      return !!this.discipline
    }
  },

  created () {
    if (this.isEdit) {
      this.form = {
        name: this.discipline.name,
        categories: this.discipline.disciplineCategories?.map(item => item.categoryId),
        forSex: this.discipline.forSex,
        noPay: this.discipline.noPay,
        alwaysPay: this.discipline.alwaysPay
      }
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        let discipline = this.discipline
        const payload = {
          name: this.form.name,
          noPay: this.form.noPay,
          forSex: this.form.forSex,
          alwaysPay: this.form.alwaysPay
        }
        if (this.isEdit) {
          await this.$store.dispatch('disciplines/update', {
            id: this.discipline.id,
            payload
          })

          if (this.discipline?.disciplineCategories) {
            const deleteDisciplines = this.discipline.disciplineCategories.map(disc => this.$store.dispatch('disciplineCategories/delete', disc.id))
            await Promise.all(deleteDisciplines)
          }
        } else {
          discipline = await this.$store.dispatch('disciplines/create', payload)
        }
        const promises = this.form.categories.map(categoryId => {
          return this.$store.dispatch('disciplineCategories/create', {
            categoryId,
            disciplineId: discipline.id
          })
        })
        await Promise.all(promises)

        this.$message.success(this.isEdit ? this.$tc('messages.success_updated_discipline') : this.$tc('messages.success_created_discipline'))
        this.$emit(this.isEdit ? 'add' : 'edit')
      } catch (error) {
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    return {
      form: {
        name: { required },
        categories: { required }
      }
    }
  }
}
</script>
