<template>
  <main-drawer title="Kotizacija" :size="900" @close="$emit('close')">
    <el-table
      :data="allData"
      style="width: 100%">
      <el-table-column
        :label="$tc('form.name')">
        <template slot-scope="scope">
          <i class="el-icon-user mr-5" />
          <template v-if="scope.row.isTeam">
            <i class="el-icon-user  mr-5" />
            <div class="name">{{ getCompetitorNames(scope.row) }}</div>
          </template>
          <div class="name" v-else>{{ scope.row.competitor.firstName }} {{ scope.row.competitor.lastName }}</div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('settings.discipline')"
        align="center">
        <template slot-scope="scope">
          <data-list :list="getDisciplinesDataList(scope.row.categories)" />
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.price')"
        align="right"
        width="200px">
        <template slot-scope="scope">
          {{ scope.row.isTeam ? getTeamPriceText(scope.row) : getPriceText(scope.row) }} RSD
        </template>
      </el-table-column>
    </el-table>

    <div class="text">Cena bez popusta: <b>{{ priceOne }} RSD</b></div>
    <div class="text">Cena sa popustom: <b>{{ priceTwo }} RSD</b></div>
    <div v-if="club.costReduction" class="text">Popust udruženja: <b>{{ club.costReduction }}%</b> ({{ priceTwo - total }}RSD)</div>
    <div v-if="club.costReduction" class="text">Ukupno: <b>{{ total }} RSD</b></div>

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">Zatvori</el-button>
        <el-button
          type="primary"
          icon="el-icon-printer"
          @click="openPrint('Kotizacija', $refs.printTable ? $refs.printTable.$el : null)">
          {{ $tc('general.print') }}
        </el-button>
      </div>
    </template>

    <print-registration-fee
      ref="printTable"
      v-show="false"
      :data="data"
      :team-data="teamData" />
  </main-drawer>
</template>

<script>
import openPrint from '@/common/print'

import PrintRegistrationFee from './PrintRegistrationFee.vue'
import registrationFeeMixin from './registration-fee'

export default {
  mixins: [registrationFeeMixin],

  components: {
    PrintRegistrationFee
  },

  props: {
    data: {
      type: Array,
      required: true
    },

    teamData: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      openPrint
    }
  },

  computed: {
    allData () {
      return [
        ...this.data,
        ...this.teamData.map(item => ({
          ...item,
          isTeam: true
        }))
      ]
    },

    club () {
      return this.allData[0]?.club
    },

    costReduction () {
      return this.club?.costReduction || 0
    },

    priceOne () {
      return this.allData.reduce((acc, item) => (item.isTeam ? this.getTeamPrice(item) : this.getPrice(item)) + acc, 0)
    },

    priceTwo () {
      return this.allData.reduce((acc, item) => (item.isTeam ? this.getTeamPrice(item, true) : this.getPrice(item, true)) + acc, 0)
    },

    total () {
      return ((100 - this.costReduction) / 100) * this.priceTwo
    }
  },

  methods: {
    getCompetitorNames (item) {
      const keys = ['competitor', 'competitorTwo', 'competitorThree', 'competitorFour']

      const competitors = keys.map(key => item[key]).filter(item => !!item).map(competitor => `${competitor.firstName} ${competitor.lastName}`)

      return competitors.join(', ')
    },

    getDisciplinesDataList (categories) {
      return categories?.reduce((acc, item) => {
        acc.push({
          label: item.category?.name
        })

        const discplines = item.disciplines?.map(disc => ({ text: disc.discipline.name }))

        acc.push(...discplines)

        return acc
      }, [])
    }
  }
}
</script>

<style lang="scss" scoped>
  .text {
    margin-top: 10px;
    text-align: right;
  }
  .name {
    display: inline-block;
    margin-left: 5px;
  }
</style>
