<template>
  <main-drawer :title="`${isEdit ? 'Izmeni' : 'Dodaj'} sudiju`" @close="$emit('close')">
    <main-input v-model="form.firstName" :label="$tc('form.first_name')" :validator="$v.form.firstName" />
    <main-input v-model="form.lastName" :label="$tc('form.last_name')" :validator="$v.form.lastName" />
    <main-select v-model="form.dan" :label="$tc('form.day')" :options="danOptions" :validator="$v.form.dan" />
    <main-select v-model="form.rang" :label="$tc('club.referee_rang')" :options="refereeRang" :validator="$v.form.rang" />

    <template v-if="$config.isWff">
      <main-input v-model="form.noCertificateWff" :label="$tc('form.no_certificate_wff')" :validator="$v.form.noCertificateWff" />
      <main-input v-model="form.noRefereeCertificateWff" :label="$tc('form.no_referee_certificate_wff')" :validator="$v.form.noRefereeCertificateWff" />
      <main-input v-model="form.noFudokanPassport" :label="$tc('form.no_fudokan_passport')" :validator="$v.form.noFudokanPassport" />
    </template>

    <main-input
      v-if="form.rang === 'OTHER'"
      v-model="form.rangOther"
      :validator="$v.form.rangOther" />
    <main-upload-input v-model="image" :label="$tc('form.image')"/>

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">{{ $tc('general.cancel') }}</el-button>
        <el-button type="primary" @click="save()">{{ isEdit ? $tc('general.edit') : $tc('general.create') }}</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>

import errorHandler from '@/common/errorHandler.js'
import { required } from 'vuelidate/lib/validators'
import { refereeDanOptions, refereeRangOptions } from '@/common/definitions'

export default {
  props: {
    club: {
      type: Object,
      default: null
    },

    referee: {
      type: Object,
      default: null
    },

    customLogic: {
      type: Boolean,
      default: null
    }
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        dan: null,
        rang: null,
        noCertificateWff: null,
        noRefereeCertificateWff: null,
        noFudokanPassport: null,
        rangOther: null
      },

      danOptions: refereeDanOptions,
      refereeRang: refereeRangOptions,
      image: null
    }
  },

  computed: {
    isEdit () {
      return !!this.referee
    }
  },

  created () {
    if (this.isEdit) {
      this.form = {
        firstName: this.referee.firstName,
        lastName: this.referee.lastName,
        dan: this.referee.dan,
        rang: this.referee.rang,
        rangOther: this.referee.rangOther,
        noCertificateWff: this.referee.noCertificateWff,
        noRefereeCertificateWff: this.referee.noRefereeCertificateWff,
        noFudokanPassport: this.referee.noFudokanPassport
      }
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      if (this.customLogic) return this.$emit('custom-add', this.form)

      this.loading = true
      try {
        let referee
        if (this.isEdit) {
          referee = await this.$store.dispatch('referees/update', {
            id: this.referee.id,
            payload: this.form
          })
        } else {
          referee = await this.$store.dispatch('referees/create', {
            clubId: this.club.id,
            ...this.form
          })
        }

        if (this.image) {
          referee = await this.$store.dispatch('referees/upload', {
            id: referee.id,
            file: this.image
          })
        }

        this.$message.success(this.isEdit ? this.$tc('messages.success_updated_referee') : this.$tc('messages.success_created_referee'))
        this.$emit(this.isEdit ? 'edit' : 'add', referee)
      } catch (error) {
        console.error(error)
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    const rules = {
      form: {
        firstName: { required },
        lastName: { required },
        dan: { required },
        rang: { required }
      }
    }

    if (this.form.rang === 'OTHER') {
      rules.form.rangOther = { required }
    }

    return rules
  }
}
</script>
