<template>
  <div>
    <error-box v-if="error" @reload="load()" />

    <loader v-else-if="loading" />

    <template v-if="!error && !loading">
      <panel :title="$tc('tournament.tournament', 2)">
        <template v-slot:actions>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="medium"
            round
            @click="openManageTournament = true">
            {{ $tc('tournament.create_tournament') }}
          </el-button>
        </template>

        <tournaments-table :data="tournaments" @edit="openEdit($event)" />

        <manage-tournament
          v-if="openManageTournament"
          :tournament="editTournament"
          @add="close()"
          @edit="close()"
          @close="close()" />
      </panel>
    </template>
  </div>
</template>

<script>
import TournamentsTable from '@/components/tournaments/TournamentsTable'
import ManageTournament from '@/components/tournaments/ManageTournament'

export default {
  components: {
    TournamentsTable,
    ManageTournament
  },

  data () {
    return {
      editTournament: null,
      openManageTournament: false,

      error: false,
      loading: false
    }
  },

  computed: {
    tournaments () {
      return this.$store.state.tournaments.data
    }
  },

  created () {
    if (!this.myOwnClub) {
      this.load()
    }
  },

  methods: {
    openEdit (tournament) {
      this.editTournament = tournament
      this.openManageTournament = true
    },

    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('tournaments/load', this.clubId)
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    close () {
      this.editTournament = null
      this.openManageTournament = false
    }
  }
}
</script>
