export default {
  computed: {
    settings () {
      return this.$store.state.app.settings
    }
  },

  methods: {
    getAllDisciplines (registration) {
      return registration.categories.reduce((acc, item) => {
        acc.push(...item.disciplines)
        return acc
      }, [])
    },

    getNoPayDisciplines (registration) {
      const kateA = [15, 16, 17, 18]
      const kateB = [12, 14]
      const allDisciplines = this.getAllDisciplines(registration)
      const hasKateA = allDisciplines.filter(d => kateA.includes(d.discipline.id))
      const hasKateB = allDisciplines.some(d => kateB.includes(d.discipline.id))

      if (hasKateA.length && hasKateB) {
        return hasKateA.map(d => d.disciplineId)
      }

      return []
    },

    getRegistrationDisciplinesCount (registration) {
      const noPayIds = this.getNoPayDisciplines(registration)
      return registration.categories
        .filter(item => !item.category.noPay || item.disciplines.some(d => d.discipline?.alwaysPay))
        .reduce((acc, item) => item.disciplines.filter(d => {
          return !noPayIds.includes(d.discipline.id) && !d.discipline?.noPay
        }).length + acc, 0)
    },

    getPrice (registration, costReduction) {
      const disciplines = this.getRegistrationDisciplinesCount(registration)
      if (registration.competitor.noPay || disciplines === 0) return 0

      if (disciplines === 1) return this.settings.single_fee_1 - (costReduction ? this.settings.single_fee_1_break : 0)
      if (disciplines === 2) return this.settings.single_fee_2 - (costReduction ? this.settings.single_fee_2_break : 0)
      return this.settings.single_fee_other - (costReduction ? this.settings.single_fee_other_break : 0)
    },

    getTeamPrice (registration, costReduction) {
      const disciplines = this.getRegistrationDisciplinesCount(registration)
      if (disciplines === 0) return 0
      return (this.settings.team_fee * disciplines) - (costReduction ? (this.settings.team_fee_break * disciplines) : 0)
    },

    getPriceText (registration) {
      const disciplines = this.getRegistrationDisciplinesCount(registration)
      if (registration.competitor.noPay || disciplines === 0) return 0

      if (disciplines === 1) return `${this.settings.single_fee_1} - ${this.settings.single_fee_1_break} = ${this.settings.single_fee_1 - this.settings.single_fee_1_break}`
      if (disciplines === 2) return `${this.settings.single_fee_2} - ${this.settings.single_fee_2_break} = ${this.settings.single_fee_2 - this.settings.single_fee_2_break}`

      return `${this.settings.single_fee_other} - ${this.settings.single_fee_other_break} = ${this.settings.single_fee_other - this.settings.single_fee_other_break}`
    },

    getTeamPriceText (registration) {
      const disciplines = this.getRegistrationDisciplinesCount(registration)

      if (disciplines === 0) return 0

      return `${this.settings.team_fee * disciplines} - ${this.settings.team_fee_break * disciplines} = ${(this.settings.team_fee * disciplines) - (this.settings.team_fee_break * disciplines)}`
    }
  }
}
