<template>
  <main-drawer :title="`${isEdit ? 'Izmeni' : 'Prijavi'} takmičara`" @close="$emit('close')">
    <main-select
      v-model="form.memberId"
      label="Takmičar"
      :disabled="isEdit"
      :options="memberOptions"
      :validator="$v.form.memberId" />

    <main-select
      v-model="form.categories"
      label="Kategorije"
      :options="categoryOptions"
      :validator="$v.form.categories"
      multiple />

    <template v-if="form.categories && !!form.categories.length">
      <main-select
        v-for="categoryId in form.categories"
        :key="categoryId"
        v-model="form.disciplines[categoryId]"
        :validator="$v.form.disciplines ? $v.form.disciplines[categoryId] : null"
        :label="`Discipline - ${getCategoryName(categoryId)}`"
        :options="getDisciplineOptions(categoryId)"
        multiple
        @input="$forceUpdate()" />
    </template>

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">Otkaži</el-button>
        <el-button type="primary" @click="save()">{{ isEdit ? 'Izmeni' : 'Prijavi' }}</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    tournament: {
      type: Object,
      required: true
    },

    club: {
      type: Object,
      required: true
    },

    competitor: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        memberId: null,
        categories: null,
        disciplines: {}
      }
    }
  },

  created () {
    if (this.competitor) {
      this.form = {
        memberId: this.competitor.competitorId,
        categories: this.competitor.categories.map(i => i.categoryId),
        disciplines: this.competitor.categories?.reduce((acc, i) => {
          acc[i.categoryId] = i.disciplines?.map(d => d.disciplineId)
          return acc
        }, {})
      }
    }
  },

  computed: {
    isEdit () {
      return !!this.competitor
    },

    disciplineErrors () {
      const data = {}

      if (this.form.categories?.length) {
        this.form.categories.forEach(id => {
          data[id] = {
            required: !!this.form.disciplines[id]?.length,
            $dirty: this.$v.form.$dirty,
            $pending: this.$v.form.$pending,
            $invalid: !this.form.disciplines[id]?.length,
            $touch: () => {}
          }
        })
      }

      return data
    },

    selectedMember () {
      if (!this.form.memberId) return

      return this.members?.find(m => m.id === this.form.memberId)
    },

    tournamentDisciplines () {
      return this.tournament.disciplines.map(d => d.discipline)
    },

    categoryOptions () {
      const categoryIdsObject = {}
      this.tournamentDisciplines.forEach(d => {
        d.disciplineCategories.forEach(cat => {
          categoryIdsObject[cat.categoryId] = true
        })
      })

      const tournamentCategories = this.allCategories.filter(category => Object.keys(categoryIdsObject).includes(category.id + '')) || []

      return tournamentCategories.map(category => ({
        id: category.id,
        text: category.name
      }))
    },

    allCategories () {
      return this.$store.state.categories.data
    },

    memberOptions () {
      return this.members?.map(member => ({
        id: member.id,
        text: member.firstName + ' ' + member.lastName
      })) || []
    },

    members () {
      return this.club?.members || []
    },

    user () {
      return this.$store.state.auth.user
    }
  },

  watch: {
    'form.categories': {
      handler (categories) {
        if (categories?.length) {
          categories.forEach(categoryId => {
            if (!this.form.disciplines[categoryId]) {
              this.form.disciplines[categoryId] = []
              this.$forceUpdate()
            }
          })
        }
      },
      deep: true
    }
  },

  methods: {
    getDisciplineOptions (categoryId) {
      const tournamentDisciplines = this.tournamentDisciplines.map(tournamentDiscipline => ({
        ...tournamentDiscipline,
        categoryIds: tournamentDiscipline.disciplineCategories.map(category => category.categoryId)
      }))

      const sexMatch = (d) => !d?.forSex || d.forSex === (this.selectedMember?.isMale ? '1' : '2')

      const disciplines = tournamentDisciplines.filter(d => sexMatch(d) && d.categoryIds.includes(categoryId))

      return disciplines.map(discipline => ({
        id: discipline.id,
        text: discipline.name
      }))
    },

    getCategoryName (categoryId) {
      return this.categoryOptions.find(category => category.id === categoryId)?.text
    },

    checkDisciplines () {
      return this.form.categories.every(id => !!this.form.disciplines?.[id]?.length)
    },

    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      if (!this.checkDisciplines()) return this.$message.error('Morate uneti barem jednu disciplinu.')

      this.loading = true

      const payload = {
        memberId: this.form.memberId,
        categories: this.form.categories.map(categoryId => ({
          id: categoryId,
          disciplines: this.form.disciplines[categoryId] || []
        }))
      }

      try {
        let tournament
        if (this.isEdit) {
          tournament = await this.$store.dispatch('tournaments/updateCompetitor', {
            id: this.competitor.id,
            payload
          })
        } else {
          tournament = await this.$store.dispatch('tournaments/registerCompetitor', payload)
        }
        this.$message.success(`Uspešno ste ${this.isEdit ? 'izmenili' : 'prijavili'} takmičara.`)
        this.$emit(this.isEdit ? 'edit' : 'add', tournament)
      } catch (error) {
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    const rules = {
      form: {
        memberId: { required },
        categories: { required },
        disciplines: {}
      }
    }

    if (this.form.categories) {
      this.form.categories.forEach(id => {
        rules.form.disciplines[id] = { required }
      })
    }

    return rules
  }
}
</script>
