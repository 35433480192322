<template>
  <div class="main-table-wrapper">
    <el-table
      :data="data"
      style="width: 100%">
      <el-table-column
        :label="$tc('general.n0')"
        width="50px">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}.
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.name')">
        <template slot-scope="scope">
          {{ scope.row.competitor.firstName }} {{ scope.row.competitor.lastName }} ({{ scope.row.competitor.isMale ? 'M' : 'Ž' }})
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('club.club_name')">
        <template slot-scope="scope">
          {{ scope.row.club.shortName }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('settings.discipline')"
        align="right">
        <template slot-scope="scope">
          <data-list :list="getDisciplinesDataList(scope.row.categories)" />
        </template>
      </el-table-column>

      <el-table-column
        v-if="canEdit"
        :label="$tc('general.actions')"
        align="right"
        width="110px">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" circle @click="$emit('edit', scope.row)" />
          <el-button type="danger" icon="el-icon-delete" circle @click="beforeRemove(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getDisciplinesDataList (categories) {
      return categories?.reduce((acc, item) => {
        acc.push({
          label: item.category?.name
        })

        const discplines = item.disciplines?.map(disc => ({ text: disc.discipline.name }))

        acc.push(...discplines)

        return acc
      }, [])
    },

    async beforeRemove (registration) {
      try {
        await this.$confirm(`Da li si siguran da želiš da ukloniš takmičara: ${registration.competitor.firstName} ${registration.competitor.lastName}`)
        this.onDelete(registration)
      } catch {}
    },

    async onDelete (registration) {
      const loading = this.$loading()
      try {
        const tournament = await this.$store.dispatch('tournaments/removeCompetitor', registration.id)
        this.$emit('remove', tournament)
        this.$message.success('Uspešno ste uklonili takmičara.')
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    }
  }
}
</script>
