<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Rb.</th>
          <th>{{ $tc('form.image') }}</th>
          <th>{{ $tc('form.name') }}</th>
          <th v-if="showClubName">{{ $tc('club.club_name') }}</th>
          <th>{{ $tc('form.date_of_birth') }}</th>
          <th>{{ $tc('form.gender') }}</th>
          <th>{{ $tc('form.belt') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="item.id">
          <td>{{ index + 1 }}.</td>
          <td style="width: 80px">
            <div class="image">
              <img :src="$file('avatars', item.avatar)" style="width: 75px; height: 75px; object-fit: cover;" class="image" />
            </div>
          </td>
          <td>{{ item.firstName }} {{ item.lastName }}</td>
          <td v-if="showClubName">{{ item.club ? item.club.shortName : '' }}</td>
          <td>{{ item.born | date }}</td>
          <td>{{ item.isMale | sex }}</td>
          <td>{{ item.belt | belt }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    showClubName: {
      type: Boolean,
      default: false
    }
  }
}
</script>
