<template>
  <main-drawer :title="`${isEdit ? 'Izmeni' : 'Dodaj'} zvaničnika`" @close="$emit('close')">
    <main-input v-model="form.firstName" :label="$tc('form.first_name')" :validator="$v.form.firstName" />
    <main-input v-model="form.lastName" :label="$tc('form.last_name')"  :validator="$v.form.lastName" />
    <main-input v-model="form.funct" :label="$('general.function')" :validator="$v.form.funct" />
    <main-upload-input v-model="image" :label="$tc('form.image')"/>

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">Otkaži</el-button>
        <el-button type="primary" @click="save()">{{ isEdit ? 'Izmeni' : 'Dodaj' }}</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    club: {
      type: Object,
      default: null
    },

    official: {
      type: Object,
      default: null
    },

    customLogic: {
      type: Boolean,
      default: null
    }
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        funct: null
      },
      image: null
    }
  },

  computed: {
    isEdit () {
      return !!this.official
    }
  },

  created () {
    if (this.isEdit) {
      this.form = {
        firstName: this.official.firstName,
        lastName: this.official.lastName,
        funct: this.official.funct
      }
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      if (this.customLogic) return this.$emit('custom-add', this.form)

      this.loading = true
      try {
        let official
        if (this.isEdit) {
          official = await this.$store.dispatch('officials/update', {
            id: this.official.id,
            payload: this.form
          })
        } else {
          official = await this.$store.dispatch('officials/create', {
            clubId: this.club.id,
            ...this.form
          })
        }

        if (this.image) {
          official = await this.$store.dispatch('officials/upload', {
            id: official.id,
            file: this.image
          })
        }

        this.$message.success(`Uspesno ste ${this.isEdit ? 'izmenili' : 'dodali'} zvaničnika.`)
        this.$emit(this.isEdit ? 'edit' : 'add', official)
      } catch (error) {
        console.error(error)
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        funct: { required }
      }
    }
  }
}
</script>
