<template>
  <div>
    <error-box v-if="error" @reload="load()" />

    <loader v-else-if="loading" />

    <template v-if="!error && !loading && club">
      <template v-if="$feature.registration_fee">
        <v-teleport to="header">
          <div>
            <el-button
              icon="el-icon-tickets"
              size="medium"
              round
              @click="registrationFeePreview = true">
              {{ $tc('general.registration_fee') }}
            </el-button>
          </div>
        </v-teleport>
      </template>

      <el-row :gutter="20" class="club-panel">
        <el-col :sm="24" :md="16">
          <ClubPanel :club="club" can-edit @edit="onClubEdit($event)" />
        </el-col>
        <el-col :sm="24" :md="8">
          <panel :title="$tc('club.club_name')" class="info-panel">
            <data-list :list="infoData" />
          </panel>
        </el-col>
      </el-row>

      <CompetitorsPanel
        :club="club"
        :tournament="tournament"
        :competitors="tournamentCompetitors"
        can-edit
        @registration-fee="registrationFeePreview = true" />

      <TeamsPanel
        :club="club"
        :tournament="tournament"
        :teams="tournamentTeams"
        can-edit
        @registration-fee="registrationFeePreview = true" />

      <CoachesPanel :club="club" :coaches="club.coaches" can-edit @add="updateClubAdd('coaches', $event)" @delete="updateClubDelete('coaches', $event)" />

      <RefereePanel :club="club" :referees="club.referees" can-edit @add="updateClubAdd('referees', $event)" @delete="updateClubDelete('referees', $event)" />

      <OfficialPanel :club="club" :officials="club.officials" can-edit @add="updateClubAdd('officials', $event)" @delete="updateClubDelete('officials', $event)" />
    </template>

    <RegistrationFee
      v-if="registrationFeePreview"
      :data="tournamentCompetitors"
      :team-data="tournamentTeams"
      @close="registrationFeePreview = null" />
  </div>
</template>

<script>
import openPrint from '@/common/print'
import registrationFeeMixin from '@/components/tournaments/registration-fee'

import ClubPanel from '@/components/clubs/ClubPanel'
import CoachesPanel from '@/components/coaches/CoachesPanel'
import RefereePanel from '@/components/referee/RefereePanel'
import TeamsPanel from '@/components/tournaments//TeamsPanel'
import OfficialPanel from '@/components/official/OfficialPanel'
import RegistrationFee from '@/components/tournaments/RegistrationFee'
import CompetitorsPanel from '@/components/tournaments//CompetitorsPanel'

export default {
  mixins: [registrationFeeMixin],

  components: {
    ClubPanel,
    TeamsPanel,
    CoachesPanel,
    RefereePanel,
    OfficialPanel,
    RegistrationFee,
    CompetitorsPanel
  },

  data () {
    return {
      openPrint,

      registrationFeePreview: false,

      error: false,
      loading: false
    }
  },

  computed: {
    allData () {
      return [
        ...this.tournamentCompetitors,
        ...this.tournamentTeams.map(item => ({
          ...item,
          isTeam: true
        }))
      ]
    },

    infoData () {
      const data = [
        {
          label: this.$tc('tournament.number_of_registered_competitors'),
          text: this.tournamentCompetitors?.length || '0'
        },
        {
          label: this.$tc('tournament.number_of_registered_disciplines'),
          text: this.countDisciplinesFromRegistrations(this.tournamentCompetitors) || '0'
        },
        {
          label: this.$tc('tournament.number_of_registered_team_disciplines'),
          text: this.countDisciplinesFromRegistrations(this.tournamentTeams) || '0'
        }
      ]

      if (this.$feature.registration_fee) {
        data.push({
          label: this.$tc('general.registration_fee'),
          text: this.$formatCurrency(this.registrationFee)
        })
      }

      return data
    },

    registrationFee () {
      return this.calculateFee(this.allData)
    },

    viewingActiveTournament () {
      return this.$route.name === 'ActiveTournamentClubView'
    },

    activeTournament () {
      return this.$store.state.app.activeTournament
    },

    tournamentId () {
      return this.viewingActiveTournament ? this.activeTournament?.id : this.$route.params.id
    },

    clubId () {
      return parseInt(this.$route.params.clubId)
    },

    tournament () {
      if (this.viewingActiveTournament) return this.activeTournament

      return this.$store.getters['tournaments/get'](this.tournamentId)
    },

    club () {
      return this.$store.getters['clubs/get'](this.clubId)
    },

    tournamentCompetitors () {
      return this.tournament?.competitors?.filter(team => team.club?.id === this.clubId) || []
    },

    tournamentTeams () {
      return this.tournament?.teams?.filter(team => team.club?.id === this.clubId) || []
    }
  },

  created () {
    this.load(!this.viewingActiveTournament)
  },

  methods: {
    calculateFee (registrations) {
      const club = registrations[0]?.club
      const price = registrations.reduce((acc, item) => (item.isTeam ? this.getTeamPrice(item, true) : this.getPrice(item, true)) + acc, 0)
      const costReduction = club?.costReduction || 0
      return ((100 - costReduction) / 100) * price
    },

    countDisciplinesFromRegistrations (registration) {
      return registration?.reduce((acc, item) => {
        const disc = item.categories.reduce((a, c) => a + c.disciplines.length, 0)
        return acc + disc
      }, 0)
    },

    updateClubDelete (property, item) {
      const newClub = {
        ...this.club,
        [property]: this.club[property].filter(i => i.id !== item.id)
      }

      this.$store.commit('clubs/setSingle', newClub)
    },

    updateClubAdd (property, item) {
      const newClub = {
        ...this.club,
        [property]: [...this.club[property], item]
      }

      this.$store.commit('clubs/setSingle', newClub)
    },

    async load (getTournament = true) {
      this.loading = true
      try {
        const promises = [
          this.$store.dispatch('coaches/load'),
          this.$store.dispatch('referees/load'),
          this.$store.dispatch('officials/load'),
          this.$store.dispatch('clubs/get', this.clubId),
          getTournament ? this.$store.dispatch('tournaments/get', this.tournamentId) : Promise.resolve()
        ]
        await Promise.all(promises)
        this.setPageData()
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    setPageData () {
      let breadcrumbs = null

      if (this.viewingActiveTournament) {
        breadcrumbs = [
          {
            text: this.$tc('tournament.tournament', 2),
            to: { name: 'Tournaments' }
          },
          {
            text: this.tournament?.name,
            to: { name: 'ActiveTournament' }
          }
        ]
      } else {
        breadcrumbs = [
          {
            text: this.$tc('tournament.tournament', 2),
            to: { name: 'Tournaments' }
          },
          {
            text: this.tournament?.name,
            to: { name: 'TournamentView', params: { id: this.tournament.id } }
          }
        ]
      }

      breadcrumbs.push({
        text: this.club?.name
      })

      this.$store.commit('app/set', {
        title: this.tournament?.name + ' -> ' + this.club?.name,
        breadcrumbs
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .club-panel {
    margin-bottom: 30px;
  }

  @media (max-width: 991px) {
    .club-panel {
      margin-bottom: 20px;
    }
    .info-panel {
      margin-top: 20px;
    }
  }
</style>
