import Vue from 'vue'
import axios from 'axios'
import factory from '../factory'

const store = factory('Tournaments')

store.actions.registerCompetitor = async ({ commit }, payload) => {
  const response = await axios.post('tournament/register-competitor', payload)
  commit('update', response.data)
  commit('setSingle', response.data)
  return response.data
}

store.actions.updateCompetitor = async ({ commit }, { id, payload }) => {
  const response = await axios.put(`tournament/competitor/${id}`, payload)
  commit('update', response.data)
  commit('setSingle', response.data)
  return response.data
}

store.actions.removeCompetitor = async ({ commit }, id) => {
  const response = await axios.delete(`tournament/competitor/${id}`)
  commit('update', response.data)
  commit('setSingle', response.data)
  return response.data
}

store.actions.registerTeam = async ({ commit }, payload) => {
  const response = await axios.post('tournament/register-team', payload)
  commit('update', response.data)
  commit('setSingle', response.data)
  return response.data
}

store.actions.updateTeam = async ({ commit }, { id, payload }) => {
  const response = await axios.put(`tournament/team/${id}`, payload)
  commit('update', response.data)
  commit('setSingle', response.data)
  return response.data
}

store.actions.removeTeam = async ({ commit }, id) => {
  const response = await axios.delete(`tournament/team/${id}`)
  commit('update', response.data)
  commit('setSingle', response.data)
  return response.data
}

store.mutations.removeImage = async (state, { tournament, id }) => {
  const newImages = (tournament.images || []).filter(i => i.id !== id)
  Vue.set(state.singles[tournament.id], 'images', newImages)
}

store.mutations.removeDocument = async (state, { tournament, id }) => {
  const newDocuments = (tournament.documents || []).filter(d => d.id !== id)
  Vue.set(state.singles[tournament.id], 'documents', newDocuments)
}

export default store
