<template>
  <main-drawer :title="isEdit ? $tc('tournament.edit_tournament') : $tc('tournament.create_tournament')" :size="600" @close="$emit('close')">
    <main-input v-model="form.name" :label="$tc('tournament.tournament_name')" :validator="$v.form.name" />
    <main-textarea v-model="form.description" :label="$tc('form.description')" :validator="$v.form.description"/>
    <main-date-picker v-model="form.startDate" type="datetime" :label="$tc('tournament.registration_start_date')" :validator="$v.form.startDate"/>
    <main-date-picker v-model="form.endDate" type="datetime" :label="$tc('tournament.registration_end_date')" :validator="$v.form.endDate"/>
    <main-select v-model="form.disciplines" :label="$tc('tournament.single_disciplines')" :options="disciplineOptions" :validator="$v.form.disciplines" multiple clearable />
    <main-select v-model="form.teamDisciplines" :label="$tc('tournament.team_disciplines')" :options="disciplineOptions" :validator="$v.form.teamDisciplines" multiple clearable />
    <main-upload-input v-model="cover" :label="$tc('general.cover_image')" />
    <main-upload-input v-model="images" :label="$tc('general.images')" multiple />
    <main-upload-input v-model="documents" :label="$tc('general.documents')" multiple />

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">{{ $tc('general.cancel') }}</el-button>
        <el-button type="primary" @click="save()">{{ isEdit ? $tc('general.edit') : $tc('general.create') }}</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    tournament: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        name: null,
        description: null,
        startDate: null,
        endDate: null,
        disciplines: [],
        teamDisciplines: []
      },

      cover: null,
      images: null,
      documents: null
    }
  },

  computed: {
    activeTournament () {
      return this.$store.state.app.activeTournament
    },

    disciplineOptions () {
      return this.disciplines?.map(d => ({
        id: d.id,
        text: d.name
      })) || []
    },

    disciplines () {
      return this.$store.state.disciplines.data
    },

    isEdit () {
      return !!this.tournament
    }
  },

  created () {
    if (this.isEdit) {
      this.form = {
        name: this.tournament.name,
        description: this.tournament.description.replaceAll('<br />', ''),
        startDate: this.tournament.startDate,
        endDate: this.tournament.endDate,
        document: this.tournament.document,
        disciplines: this.tournament.disciplines?.map(disc => disc.disciplineId),
        teamDisciplines: this.tournament.teamDisciplines?.map(disc => disc.disciplineId)
      }
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        let tournament = this.tournament

        const payload = {
          name: this.form.name,
          description: this.form.description,
          startDate: this.form.startDate,
          endDate: this.form.endDate || undefined
        }

        if (this.isEdit) {
          tournament = await this.$store.dispatch('tournaments/update', {
            id: this.tournament.id,
            payload
          })
        } else {
          tournament = await this.$store.dispatch('tournaments/create', payload)
        }

        await this.syncDisciplines(tournament.disciplines, this.form.disciplines, tournament)
        await this.syncDisciplines(tournament.teamDisciplines, this.form.teamDisciplines, tournament)

        if (this.documents?.length) {
          const newDocumentsPromise = this.documents.map(document => this.$store.dispatch('documents/create', {
            path: document,
            tournamentId: tournament.id,
            name: document.name,
            size: document.size
          }).then(obj => this.$store.dispatch('documents/upload', {
            id: obj.id,
            file: document
          })))

          await Promise.all(newDocumentsPromise)
        }

        if (this.images?.length) {
          const newImagesPromise = this.images.map(image => this.$store.dispatch('tournamentImages/create', {
            path: image,
            tournamentId: tournament.id,
            name: image.name,
            size: image.size
          }).then(obj => this.$store.dispatch('tournamentImages/upload', {
            id: obj.id,
            file: image
          })))

          await Promise.all(newImagesPromise)
        }

        if (this.cover) {
          tournament = await this.$store.dispatch('tournaments/upload', {
            id: tournament.id,
            file: this.cover
          })
        }

        this.updateActiveTournament(tournament)

        this.$message.success(`Uspešno ste ${this.isEdit ? 'izmenili' : 'napravili'} turnir.`)
        this.$emit(this.isEdit ? 'add' : 'edit')
      } catch (error) {
        console.log(error)
        errorHandler(error)
      } finally {
        this.loading = false
      }
    },

    updateActiveTournament (tournament) {
      if (this.activeTournament?.id === tournament.id || tournament.isActive) {
        this.$store.commit('app/setActiveTournament', tournament.isActive ? tournament : null)
      }
    },

    syncDisciplines (currentDisciplines, newIds, tournament) {
      const currentIds = currentDisciplines?.map(disc => disc.disciplineId) || []

      const toDelete = currentIds.filter(id => !newIds.includes(id))
      const toCreate = newIds.filter(id => !currentIds.includes(id))

      const toDeleteIds = toDelete.map(id => currentDisciplines.find(disc => id === disc.disciplineId))

      const deletePromises = toDeleteIds.map(disc => this.$store.dispatch('tournamentDisciplines/delete', disc.id)) || []

      const createPromises = toCreate.map(disciplineId => {
        return this.$store.dispatch('tournamentDisciplines/create', {
          disciplineId,
          tournamentId: tournament.id
        })
      }) || []

      return Promise.all([...deletePromises, ...createPromises])
    }
  },

  validations () {
    return {
      form: {
        name: { required },
        description: { required },
        startDate: { required },
        disciplines: { required },
        teamDisciplines: { required }
      }
    }
  }
}
</script>
