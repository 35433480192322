<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>{{ $tc('form.name') }}</th>
          <th>{{ $tc('settings.discipline') }}</th>
          <th style="text-align: right;">{{ $tc('form.price') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(registration, index) in allData" :key="registration.id + index">
          <td>
            <template v-if="registration.isTeam">{{ getCompetitorNames(registration) }}</template>
            <template v-else>{{ registration.competitor.firstName }} {{ registration.competitor.lastName }}</template>
          </td>
          <td>
            <div
              v-for="(item, index) in getDisciplinesDataList(registration.categories)"
              :key="index"
              class="data-list-item">
              <span v-if="item.label" class="label">{{ item.label }}</span>
              <span v-if="item.text" class="text">{{ item.text }}</span>
            </div>
          </td>
          <td style="text-align: right;">{{ registration.isTeam ? getTeamPriceText(registration) : getPriceText(registration) }} RSD</td>
        </tr>
      </tbody>
    </table>

    <div style="text-align: right; line-height: 1.5; margin-top: 15px;">Cena bez popusta: <b>{{ priceOne }} RSD</b></div>
    <div style="text-align: right; line-height: 1.5;">Cena sa popustom: <b>{{ priceTwo }} RSD</b></div>
    <div v-if="club.costReduction" style="text-align: right; line-height: 1.5;">Popust udruženja: <b>{{ club.costReduction }}%</b> ({{ priceTwo - total }}RSD)</div>
    <div v-if="club.costReduction" style="text-align: right; line-height: 1.5;">Ukupno: <b>{{ total }} RSD</b></div>
  </div>
</template>

<script>
import registrationFeeMixin from './registration-fee'

export default {
  mixins: [registrationFeeMixin],

  props: {
    data: {
      type: Array,
      required: true
    },

    teamData: {
      type: Array,
      required: true
    }
  },

  computed: {
    allData () {
      return [
        ...this.data,
        ...this.teamData.map(item => ({
          ...item,
          isTeam: true
        }))
      ]
    },

    club () {
      return this.allData[0]?.club
    },

    costReduction () {
      return this.club.costReduction || 0
    },

    priceOne () {
      return this.allData.reduce((acc, item) => (item.isTeam ? this.getTeamPrice(item) : this.getPrice(item)) + acc, 0)
    },

    priceTwo () {
      return this.allData.reduce((acc, item) => (item.isTeam ? this.getTeamPrice(item, true) : this.getPrice(item, true)) + acc, 0)
    },

    total () {
      return ((100 - this.costReduction) / 100) * this.priceTwo
    }
  },

  methods: {
    getCompetitorNames (item) {
      const keys = ['competitor', 'competitorTwo', 'competitorThree', 'competitorFour']
      const competitors = keys.map(key => item[key]).filter(item => !!item).map(competitor => `${competitor.firstName} ${competitor.lastName}`)
      return competitors.join(', ')
    },

    getDisciplinesDataList (categories) {
      return categories?.reduce((acc, item) => {
        acc.push({ label: item.category?.name })
        const discplines = item.disciplines?.map(disc => ({ text: disc.discipline.name }))
        acc.push(...discplines)
        return acc
      }, [])
    }
  }
}
</script>
