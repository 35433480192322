<template>
  <div>
    <panel title="Pojedinačne discipline" collapsable>
      <data-list :list="disciplinesData" />
    </panel>

    <panel title="Ekipne discipline" collapsable>
      <data-list :list="teamDisciplinesData" />
    </panel>
  </div>
</template>

<script>
export default {
  props: {
    tournament: {
      type: Object,
      required: true
    }
  },

  computed: {
    disciplinesData () {
      return [
        { text: '7 godina i mlađi (poletarci)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno D nivo' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Kihon kumite' },
        { label: 'Kihon ippon kumite' },

        { text: '8-9 godina (mlađi pioniri)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno D nivo' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Kihon kumite' },
        { label: 'Kihon ippon kumite' },
        { label: 'Kobudo 7-9 godina' },
        { label: 'Karate-Do borba -35kg Ž' },
        { label: 'Karate-Do borba +35kg Ž' },
        { label: 'Karate-Do borba -37kg M' },
        { label: 'Karate-Do borba +37kg M' },

        { text: '10-11 godina (pioniri)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno D nivo' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno B nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Kihon kumite' },
        { label: 'Jiyu ippon kumite pojedinačno' },
        { label: 'Karate-Do borba -37kg Ž' },
        { label: 'Karate-Do borba +37kg Ž' },
        { label: 'Karate-Do borba -42kg M' },
        { label: 'Karate-Do borba +42kg M' },

        { text: '12-13 godina (stariji pioniri)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno D nivo' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno B nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Kihon kumite' },
        { label: 'Jiyu ippon kumite pojedinačno' },
        { label: 'Kobudo 10-13 godina' },
        { label: 'Karate-Do borba -47kg Ž' },
        { label: 'Karate-Do borba +47kg Ž' },
        { label: 'Karate-Do borba -52kg M' },
        { label: 'Karate-Do borba +52kg M' },

        { text: '14-15 godina (kadeti)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno B nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Ko-go kumite pojedinačno' },
        { label: 'Fuku-go' },
        { label: 'Kobudo' },
        { label: 'Karate-Do borba -52kg Ž' },
        { label: 'Karate-Do borba +52kg Ž' },
        { label: 'Karate-Do borba -57kg M' },
        { label: 'Karate-Do borba +57kg M' },

        { text: '16-17 godina (juniori)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno B nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Ko-go kumite pojedinačno' },
        { label: 'Fuku-go' },
        { label: 'Kobudo' },
        { label: 'Karate-Do borba -60kg Ž' },
        { label: 'Karate-Do borba +60kg Ž' },
        { label: 'Karate-Do borba -65kg M' },
        { label: 'Karate-Do borba +65kg M' },

        { text: '18-20 godina (omladinci)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno B nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Fuku-go' },
        { label: 'Kumite pojedinačno' },
        { label: 'Kobudo' },
        { label: 'Tamashiwari' },
        { label: 'Karate-Do borba -60kg Ž' },
        { label: 'Karate-Do borba +60kg Ž' },
        { label: 'Karate-Do borba -75kg M' },
        { label: 'Karate-Do borba +75kg M' },

        { text: '21-34 godina (mlađi seniori)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno B nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Fuku-go' },
        { label: 'Kumite pojedinačno' },
        { label: 'Kobudo' },
        { label: 'Tamashiwari' },
        { label: 'Karate-Do borba -60kg Ž' },
        { label: 'Karate-Do borba +60kg Ž' },
        { label: 'Karate-Do borba -78kg M' },
        { label: 'Karate-Do borba +78kg M' },

        { text: '35-49 godina (stariji seniori)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno C nivo' },
        { label: 'Kata pojedinačno B nivo' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Fuku-go' },
        { label: 'Kumite pojedinačno' },
        { label: 'Kobudo' },
        { label: 'Tamashiwari' },
        { label: 'Karate-Do borba pojedinačno A' },

        { text: '50+ godina (veterani)' },
        { label: 'Kata pojedinačno (para karate)' },
        { label: 'Kata pojedinačno A nivo' },
        { label: 'Fuku-go' },
        { label: 'Kumite pojedinačno' },
        { label: 'Kobudo' },
        { label: 'Tamashiwari' },
        { label: 'Karate-Do borba pojedinačno A' }
      ]
    },

    teamDisciplinesData () {
      return [
        { text: '7 godina i mlađi (poletarci)' },
        { label: 'Kata ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },

        { text: '8-9 godina (mlađi pioniri)' },
        { label: 'Kata ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },
        { label: 'Karate-Do borba ekipno' },

        { text: '10-11 godina (pioniri)' },
        { label: 'Kata ekipno' },
        { label: 'Jiyu ippon kumite ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },
        { label: 'Karate-Do borba ekipno' },

        { text: '12-13 godina (stariji pioniri)' },
        { label: 'Kata ekipno' },
        { label: 'Jiyu ippon kumite ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },
        { label: 'Karate-Do borba ekipno' },

        { text: '14-15 godina (kadeti)' },
        { label: 'Kata ekipno' },
        { label: 'Ko-go kumite ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },
        { label: 'Karate-Do borba ekipno' },

        { text: '16-17 godina ( juniori)' },
        { label: 'Kata ekipno' },
        { label: 'Ko-go kumite ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },
        { label: 'Karate-Do borba ekipno' },

        { text: '18-20 godina ( omladinci)' },
        { label: 'Kata ekipno' },
        { label: 'Kumite ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },
        { label: 'Karate-Do borba ekipno' },

        { text: '21-34 godina ( mlađi seniori)' },
        { label: 'Kata ekipno' },
        { label: 'Kumite ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },
        { label: 'Karate-Do borba ekipno' },

        { text: '35-49 godina ( stariji seniori)' },
        { label: 'Kata ekipno' },
        { label: 'Kumite ekipno' },
        { label: 'Enbu (M/M)' },
        { label: 'Enbu (M/Ž)' },
        { label: 'Karate-Do borba ekipno' }

      ]
    }
  }
  // methods: {
  //   getDisciplinesSortedByCategories (disciplines) {
  //     disciplines = disciplines.map(d => d.discipline)
  //     const categoryIdsObject = {}
  //     disciplines.forEach(d => {
  //       d.disciplineCategories.forEach(cat => {
  //         categoryIdsObject[cat.categoryId] = true
  //       })
  //     })

  //     const tournamentCategories = this.allCategories.filter(category => Object.keys(categoryIdsObject).includes(category.id + '')) || []

  //     return tournamentCategories.map(category => ({
  //       text: category.name,
  //       label: this.getDisciplineFromCategory(disciplines, category.id).join(', ')
  //     }))
  //   },

  //   getDisciplineFromCategory (disciplines, categoryId) {
  //     const tournamentDisciplines = disciplines.map(tournamentDiscipline => ({
  //       ...tournamentDiscipline,
  //       categoryIds: tournamentDiscipline.disciplineCategories.map(category => category.categoryId)
  //     }))

  //     const sexMatch = (d) => !d?.forSex || d.forSex === (this.selectedMember?.isMale ? '1' : '2')

  //     return tournamentDisciplines.filter(d => sexMatch(d) && d.categoryIds.includes(categoryId)).map(discipline => discipline.name)
  //   }
  // }
}
</script>
