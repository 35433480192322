<template>
  <div class="panel">
    <div
      v-if="title"
      class="header"
      @click="expandOnClick()">
      <h3>{{ title }}</h3>

      <div class="actions">
        <span @click.stop>
          <slot name="actions" @click.stop />
        </span>

        <el-button
          v-if="collapsable"
          class="ml-10"
          size="medium"
          :icon="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          circle />
      </div>
    </div>

    <el-collapse-transition>
      <div v-show="!collapsable || expanded">
        <div v-if="$slots.default" class="content">
          <slot />
        </div>

        <div
          v-if="$slots.footer"
          class="footer">
          <slot name="footer" />
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },

    collapsable: {
      type: Boolean,
      default: false
    },

    initiallyExpanded: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expanded: this.initiallyExpanded
    }
  },

  methods: {
    expandOnClick () {
      if (this.collapsable) {
        this.expanded = !this.expanded
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    overflow: hidden;
    .header {
      width: 100%;
      padding: 20px 30px;
      box-sizing: border-box;
      border-bottom: 1px solid $border;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      h3 {
        font-size: 14px;
        color: $dark;
        letter-spacing: 0.5px;
        margin: 0px;
      }
    }
    .content {
      width: 100%;
      padding: 20px 30px 20px 30px;
      box-sizing: border-box;
      overflow-x: auto;
    }
    .footer {
      width: 100%;
      padding: 20px 30px;
      box-sizing: border-box;
      border-top: 1px solid $border;
    }
  }

  .panel + .panel {
    margin-top: 20px;
  }

  .theme-dark {
    .panel {
      background-color: $darker;
      .header {
        border-color: rgba(255, 255, 255, 0.1);
        h3 {
          color: #fff;
        }
      }
    }
  }

  .panel + .panel {
    margin-top: 20px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  @media (max-width: 991px) {
    .panel {
      .header {
        flex-direction: column;
        .actions {
          margin-top: 15px;
        }
      }
    }
  }
</style>
