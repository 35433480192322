<template>
  <panel title="Activity" collapsable>
    <el-table
      :data="data"
      style="width: 100%">
      <el-table-column
        label="Change">
        <template slot-scope="scope">
          <b>{{ scope.row.user.name }}</b>
          {{ $tc(`changes.${scope.row.type}`) }}
          <b>{{ getItemName(scope.row) }}</b>
        </template>
      </el-table-column>

      <el-table-column
        label="Date"
        width="170px"
        align="right">
        <template slot-scope="scope">
          {{ scope.row.createdAt | date('LLL') }}
        </template>
      </el-table-column>
    </el-table>
  </panel>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },

  methods: {
    getItemName (change) {
      if (['REGISTER_COMPETITOR', 'COMPETITOR_UPDATE', 'COMPETITOR_DELETE'].includes(change.type)) return this.getName(change.item)
      if (['REGISTER_TEAM', 'TEAM_UPDATE', 'TEAM_DELETE'].includes(change.type)) return change.item?.map(i => this.getName(i)).join(', ')
    },

    getName (member) {
      if (!member) return ''
      return `${member.firstName} ${member.lastName}`
    }
  }
}
</script>
