<template>
  <div class="form-group">
    <label v-if="label">{{ label }}: <span
      v-if="isRequired"
      class="required">*</span></label>
    <div :class="{ error: hasError }">
      <slot />
    </div>
    <span
      v-if="errors.length && validator"
      class="errors">
      <span
        v-for="error in errors"
        :key="error"
        class="help-block text-danger">{{ error }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },

    validator: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      messages: {
        required: this.$tc('validation.required'),
        isTruthy: this.$tc('validation.isTruthy'),
        email: this.$tc('validation.email'),
        integer: this.$tc('validation.integer'),
        url: this.$tc('validation.url'),
        minLength: this.$tc('validation.minLength')
      }
    }
  },

  computed: {
    hasError () {
      return this.errors.length > 0
    },

    isRequired () {
      return this.validator && Object.prototype.hasOwnProperty.call(this.validator, 'required')
    },

    errors () {
      if (this.validator && this.validator.$dirty && !this.validator.$pending && this.validator.$invalid) {
        return Object.keys(this.validator).filter((prop) => !prop.startsWith('$') && !this.validator[prop]).map((prop) => {
          if (this.validator.$params?.[prop]?.message) {
            return this.validator.$params[prop].message
          }
          return this.messages[prop]
        })
      }

      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  display: block;
  margin: 5px 0;
  box-sizing: border-box;
  .errors {
    display: block;
    min-height: 15px;
    font-size: 11px;
    margin-top: 5px;
    span.text-danger {
      color: #d23131;
    }
  }
  label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.form-group + .form-group {
  margin-top: 15px;
}
</style>
