import Vue from 'vue'
import axios from 'axios'

export default entity => {
  const state = {
    data: null,
    singles: {}
  }

  const getters = {
    get: state => id => state.singles[id],
    list: state => state.data || []
  }

  const actions = {
    async load ({ commit }, payload) {
      if (payload?.params) {
        const newParams = {}

        Object.keys(payload.params).forEach(key => {
          newParams[`q[${key}]`] = payload.params[key]
        })

        if (payload.order) {
          Object.keys(payload.order).forEach(key => {
            newParams[`by[${key}]`] = payload.order[key]
          })
        }
        if (payload.skip) {
          newParams.skip = payload.skip
        }

        payload.params = newParams
      }

      try {
        const response = await axios.get('model/' + entity, payload)
        if (!response.data) throw new Error(response.data)
        commit('set', response.data)
        response.data.forEach(item => commit('setSingle', item))
        return response.data
      } catch (e) {
        commit('set', null)
        return Promise.reject(e)
      }
    },

    async get ({ commit }, id) {
      try {
        const response = await axios.get(`model/${entity}/${id}`)
        if (!response.data) throw new Error(response.data)
        commit('add', response.data)
        commit('setSingle', response.data)
        return response.data
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async create ({ commit }, payload) {
      const response = await axios.post('model/' + entity, {
        obj: payload,
        returnObj: true
      })
      commit('add', response.data.item)
      commit('setSingle', response.data.item)
      return response.data.item
    },

    async update ({ commit }, { id, payload }) {
      const response = await axios.put(`model/${entity}/${id}`, {
        obj: payload,
        returnObj: true
      })
      commit('update', response.data.item)
      commit('setSingle', response.data.item)
      return response.data.item
    },

    async delete ({ commit }, id) {
      await axios.delete(`model/${entity}/${id}`)
      return commit('delete', id)
    },

    async upload ({ commit }, { id, file, params = null }) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('returnObj', true)

      if (params) {
        for (const field in params) formData.append(field, params[field])
      }

      const response = await axios.post(`model/${entity}/${id}`, formData)
      commit('update', response.data.item)
      commit('setSingle', response.data.item)
      return response.data.item
    },

    async action ({ commit }, { id, action, payload = null }) {
      const response = await axios.post(`model/${entity}/${id}/${action}`, payload)
      if (response.data?.success) {
        commit('update', response.data.item)
        commit('setSingle', response.data.item)
      }
      return response.data
    }
  }

  const mutations = {
    add (state, data) {
      if (state.data) {
        const exists = state.data.find(item => parseInt(item.id) === parseInt(data.id))
        if (!exists) state.data.push(data)
      } else {
        Vue.set(state, data, [data])
      }
    },

    set (state, data) {
      Vue.set(state, 'data', data)
    },

    update (state, data) {
      if (state.data) {
        const idx = state.data.findIndex(item => item.id === data.id)
        Vue.set(state.data, idx, data)
      }
    },

    setSingle (state, data) {
      Vue.set(state.singles, data.id, data)
    },

    delete (state, id) {
      Vue.delete(state.singles, id)

      if (state.data) {
        const idx = state.data.findIndex(item => item.id === id)
        if (idx >= 0) state.data.splice(idx, 1)
      }
    }
  }

  return {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
  }
}
