import Vue from 'vue'
import store from '../store'
import moment from 'moment'

import { belts, refereeDans, refereeRangs, teamTypes } from './definitions'

Vue.filter('money', (value) => {
  return Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    style: 'currency',
    currency: store.state.auth.user.settings.currency
  }).format(value)
})

Vue.filter('date', (date, format = 'LLL') => {
  // if (format === 'LLL') format = 'DD.MM.YYYY HH:mm'
  return moment(date).format(format)
})

Vue.filter('belt', (value) => {
  return belts[value] || null
})

Vue.filter('refereeDan', (value) => {
  return refereeDans[value] || null
})

Vue.filter('teamType', (value) => {
  return teamTypes[value] || null
})

Vue.filter('refereeRang', (value) => {
  return refereeRangs[value] || null
})

Vue.filter('sex', (value) => {
  if (value === null) return null
  return value ? 'Muški' : 'Ženski'
})
