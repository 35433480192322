export default {
  general: {
    home: 'Početna',
    print: 'Štampaj',
    informations: 'Informacije',
    dashboard: 'Početna',
    other: 'Ostalo',
    club: 'Sportsko udruženje | Sportska udruženja',
    registration_fee: 'Kotizacija',
    registration_fee_discount: 'Registration fee discount',
    male: 'Muški',
    female: 'Ženski',
    mixed: 'Miks',
    save: 'Sačuvaj',
    both: 'Oba',
    men: 'Muškarci',
    women: 'Žene',
    edit: 'Izmeni',
    create: 'Napravi',
    actions: 'Akcije',
    next: 'Dalje',
    ops: 'Uuups!',
    prev_step: 'Prethodni korak',
    page_doesnt_exists: 'Strana kojoj pokusašavaš da pristupiš ne postoji.',
    back_to_home: 'Nazad na početnu',
    type: 'Tip',
    cancel: 'Otkazi',
    function: 'Funkcija',
    documents: 'Dokumenta',
    images: 'Slike',
    n0: 'Rb.',
    cover_image: 'Naslovna slika'
  },

  menu: {
    my_club: 'Moje Sportsko Udruženje',
    open_tournament: 'Otvoreni turnir',
    admin_panel: 'Admin Panel',
    users: 'Korisnici'
  },

  auth: {
    login: 'Prijava',
    log_out: 'Odjavi se',
    register: 'Registracija',
    no_account_register: 'Nemate nalog? Registrujte se',
    have_account_login: 'Imate nalog? Prijavite se',
    sign_in: 'Prijavi se',
    sign_up: 'Registruj se',
    profile_information: 'Informacije Profila',
    club_info: 'Informacije Sportske Organizacije',
    forgot_password: 'Zaboravili ste lozinku?',
    reset_password: 'Resetuj lozinku',
    add_coach: 'Dodaj trenera'
  },

  form: {
    name: 'Ime',
    city: 'Grad',
    address: 'Adresa',
    username: 'Korisničko ime',
    price: 'Cena',
    website: 'Web sajt',
    contact_person: 'Kontakt osoba',
    discount: 'Popust',
    description: 'Opis',
    mobile: 'Mobilni',
    email: 'Email',
    enter_email: 'Unesite email adresu',
    password: 'Lozinka',
    enter_password: 'Unesite lozinku',
    image: 'Slika',
    date_of_birth: 'Datum rođenja',
    gender: 'Pol',
    belt: 'Kaiš',
    day: 'Dan',
    first_name: 'Ime',
    last_name: 'Prezime'
  },

  tournament: {
    teams: 'Ekipe',
    competitors: 'Takmičari',
    tournament_name: 'Naziv turnira',
    tournament: 'Turnir | Turniri',
    create_tournament: 'Napravi turnir',
    all_registered_competitors: 'Svi prijavljeni takmičari',
    all_registered_teams: 'Sve prijavljene ekipe',
    my_registered_competitors: 'Moji prijavljeni takmičari',
    my_registered_teams: 'Moje prijavljene ekipe',
    close_applications: 'Zatvori prijave',
    edit_tournament: 'Izmeni turnir',
    registration_start_date: 'Početak prijava',
    registration_end_date: 'Završetak prijava',
    single_disciplines: 'Pojedinačne discipline',
    team_disciplines: 'Timske discipline',
    number_of_registered_competitors: 'Broj prijavljenih takmičara',
    number_of_registered_disciplines: 'Broj prijavljenih pojedinačnih disciplina',
    number_of_registered_team_disciplines: 'Broj prijavljenih timskoh disciplina',
    number_of_registered_clubs: 'Broj prijavljenih sportskih udruženja',
    ask_delete_tournament: 'Da li si siguran da želiš da obrišeš ovaj turnir',
    register_competitor: 'Prijavi takmičara',
    register_team: 'Prijavi ekipu'
  },

  club: {
    club_name: 'Naziv sportskog udruženja',
    short_name: 'Skraćeni Naziv Sportske Organizacije',
    my_federation_information: 'Informacije sportskog udruženja',
    coach: 'Trener | Treneri',
    official: 'Zvaničnik | Zvaničnici',
    referee: 'Sudija | Sudije',
    referee_rang: 'Rang sudije',
    add_official: 'Dodaj zvaničnika',
    ask_delete_club: 'Da li si siguran da želiš da ukloniš ovo udruženje',
    ask_delete_official: 'Da li si siguran da želiš da ukloniš zvaničnika'
  },

  profile: {
    my_profile: 'Moj profil',
    profile_settings: 'Podešavanja profila',
    change_password: 'Promeni lozinku',
    current_password: 'Trenutna lozinka',
    new_password: 'Nova lozinka'
  },

  settings: {
    settings: 'Podešavanja',
    category: 'Kategorija | Kategorije',
    discipline: 'Disciplina | Discipline',
    allowed_sexes: 'Dozvoljeni polovi',
    general_settings: 'Generalna podešavanja',
    website_name: 'Naziv web sajta',
    website_description: 'Opis web sajta',
    logo: 'Logo',
    create_category: 'Napravi kategoriju',
    create_discipline: 'Napravi disciplinu',
    edit_discipline: 'Izmeni disciplinu',
    edit_category: 'Izmeni kategoriju',
    one_discipline: 'Jedna disciplina',
    two_disciplines: 'Dve discipline',
    three_or_more_disciplines: 'Tri ili više disciplina',
    team_disciplines: 'Timske discipline',
    discipline_always_chargeable: 'This discipline is always chargable',
    discipline_not_chargeable: 'This discipline is not chargable',
    category_not_chargeable: 'This category is not chargable'
  },

  messages: {
    success_sign_in: 'Uspešno ste se prijavili.',
    error_email_password_combination: 'Pogresna email adresa ili lozinka.',
    success_register: 'Uspesno ste se registrovali. Možete se prijaviti',
    settings_successfully_updated: 'Podešavanja uspešno sačuvana.',
    success_remove_discipline: 'Disciplina je obrisana uspešno.',
    success_remove_category: 'Kategorija je obrisana uspešno.',
    ask_delete_official: 'Da li si siguran da želiš da ukloniš zvaničnika',
    ask_delete_tournament: 'Da li si siguran da želiš da obrišeš ovaj turnir?',
    ask_remove_discipline: 'Da li ste sigurni da želite da obrišete ovu disciplinu',
    ask_remove_category: 'Da li ste sigurni da želite da obrišete ovu kategoriju',
    ask_remove_coach: 'Are you sure you want to remove this coach',
    ask_remove_team: 'Are you sure you want to remove this team',
    ask_remove_referee: 'Are you sure you want to remove this referee',
    success_updated_referee: 'Successfully updated referee.',
    success_created_referee: 'Successfully created referee.',
    success_updated_discipline: 'Disciplinaje je izmenjena uspešno.',
    success_created_discipline: 'Disciplina je napravljena uspešno.',
    success_updated_category: 'Kategorija je izmenjena uspešno.',
    success_created_category: 'Kategorija je napravljena uspešno.',
    success_updated_profile: 'Profil je izmenjen uspešno.',
    success_changed_password: 'Lozinka uspešno promenjena.',
    success_deleted_tournament: 'Uspešno ste uklonili turnir.',
    success_deleted_team: 'Uspešno ste uklonili tim.',
    success_deleted_referee: 'Uspešno ste uklonili sudiju.',
    success_deleted_club: 'Uspešno ste uklonili sportsko udruženje.',
    success_deleted_official: 'Uspešno ste uklonili zvaničnika.',
    success_deleted_coach: 'Uspešno ste uklonili sudiju.',
    success_updated_coach: 'Uspesno ste izmenili trenera.',
    success_created_coach: 'Uspesno ste dodali trenera.'
  },

  validation: {
    required: 'Ovo polje je obavezno.',
    isTruthy: 'Ovo polje je obavezno.',
    email: 'Unesite ispravnu email adresu.',
    integer: 'Polje mora biti broj.',
    url: 'Unesite ispravnu URL adresu.',
    minLength: 'Broj karaktera mora biti veći.'
  },

  changes: {
    REGISTER_COMPETITOR: 'registered competitor',
    COMPETITOR_UPDATE: 'updated competitor',
    COMPETITOR_DELETE: 'deleted competitor',
    REGISTER_TEAM: 'registered a team',
    TEAM_UPDATE: 'updated team',
    TEAM_DELETE: 'deleted team'
  }
}
