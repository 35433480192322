import Vue from 'vue'
import axios from 'axios'

export default {
  state: {
    user: null
  },

  actions: {
    async login ({ commit }, data) {
      try {
        const response = await axios.post('/login', data)
        commit('setAll', response.data)
        return Promise.resolve(response.data.user)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async register (context, payload) {
      try {
        const response = await axios.post('/register', payload)
        return response.data.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async validate (context, payload) {
      try {
        const response = await axios.post('/register/validate', payload)
        return Promise.resolve(response.data.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update ({ commit }, payload) {
      try {
        const response = await axios.put('/profile', payload)
        commit('setUser', response.data.data)
        return Promise.resolve(response.data.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async changePassword ({ commit }, payload) {
      try {
        const response = await axios.put('/profile/change-password', payload)
        commit('setUser', response.data.data)
        return Promise.resolve(response.data.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    logout ({ commit }) {
      localStorage.removeItem('token')
      axios.defaults.headers.common.Authorization = null
      commit('setUser', null)
    },

    async me ({ commit, dispatch }) {
      try {
        const response = await axios.get('/me')
        commit('setUser', response.data)
        return Promise.resolve(response.data.data)
      } catch (error) {
        dispatch('logout')
        return Promise.reject(error)
      }
    }
  },

  mutations: {
    setUser (state, user) {
      Vue.set(state, 'user', user)
    },

    updateUsersClub (state, club) {
      Vue.set(state.user, 'club', club)
    },

    setAll (state, { user, token }) {
      if (token && user) {
        Vue.set(state, 'user', user)
        localStorage.setItem('token', token)
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
      } else {
        state.user = null
        axios.defaults.headers.common.Authorization = null
        localStorage.removeItem('token')
      }
    }
  },

  namespaced: true
}
